// import logo from './logo.svg';
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import ShopPage from './pages/ShopPage';

function App() {
  const id = 123;
  console.log(id);

  return (
    <>
      <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/:shop/:page' element={<ShopPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
