import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useParams } from 'react-router-dom';
import content from '../content.json';
import './ShopPage.css';

const ShopPage = props => {
  const params = useParams();
  console.log(params);
  const [index, setIndex] = useState(content[params.shop][parseInt(params.page.substring(4)) - 1] ? parseInt(params.page.substring(4)) - 1 : 0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'flipped_dialogue',
        event_params: {
          eventType: 'exploration',
          npcName: params.shop, // dynamic
          dialoguePage: index // dynamic
        }
      });
    }
  }, [index]);

  return (
    <>
      <Carousel fade activeIndex={index} onSelect={handleSelect} interval={null}>
        {content[params.shop][0] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][0]} alt='page1' />
          </Carousel.Item>
        )}
        {content[params.shop][1] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][1]} alt='page2' />
          </Carousel.Item>
        )}
        {content[params.shop][2] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][2]} alt='page3' />
          </Carousel.Item>
        )}
        {content[params.shop][3] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][3]} alt='page4' />
          </Carousel.Item>
        )}
        {content[params.shop][4] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][4]} alt='page5' />
          </Carousel.Item>
        )}
        {content[params.shop][5] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][5]} alt='page6' />
          </Carousel.Item>
        )}
        {content[params.shop][6] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][6]} alt='page7' />
          </Carousel.Item>
        )}
        {content[params.shop][7] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][7]} alt='page8' />
          </Carousel.Item>
        )}
        {content[params.shop][8] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][8]} alt='page9' />
          </Carousel.Item>
        )}
        {content[params.shop][9] && (
          <Carousel.Item className='item'>
            <img className='d-block' src={content[params.shop][9]} alt='page10' />
          </Carousel.Item>
        )}
      </Carousel>
    </>
  );
};

export default ShopPage;
